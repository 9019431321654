// @import "compass/css3";





#hero1{
 background:url(https://4.bp.blogspot.com/_AQ0vcRxFu0A/S9shDGGyMTI/AAAAAAAAAYk/kn3WTkY2LoQ/s1600/IMG_0714.JPG);
  background-size:cover;
  background-position:center center;
  background-attachment:fixed;
//   height:500px;

}

.hero-fixed, .content{
  text-align:center; 
  position:relative;
  width: 100%;
  margin-bottom: 20px;
}

// .inner{
// //   height:200px;
//   position: relative;
// }

.hero-fixed .inner{
  background: rgba(0,0,0,0.7) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC) repeat;
  min-height:600px;

}


.copy{
  position:absolute; 
  top:50%; 
  left: 25%;
  height:10em; 
  margin-top:-5em; 
  width:50%;
}

.hero-fixed h1, .hero-fixed p{ 
  color:#fff;
}

.content h1, .content p{
  color:#333;
}

// h1{
//   margin:0px;
//   font-family: 'Roboto Slab', serif;
//   font-weight:400;
//   font-size:32px;
//   padding:0px 20px;
// }

// p{
//   font-family: 'Noto Sans', sans-serif; 
//   font-size:14px;
//   padding:0px 20px;
// }

