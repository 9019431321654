.hero{
    width: 100%;
    // height: 100vh;
    background-color: #5E043D;
    color: var(--white);
    padding: 10rem 0;
    margin-top: 6rem;
    position: relative;
    @media(max-width: 767px){
       
        padding: 0;
        
    }

    &__wrapper{
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        @media(max-width: 767px){
            flex-direction: column-reverse;
        }
    }

    &__content{
         
            max-width: 45rem;
            @media(max-width: 991px){
                max-width: 35rem;
                   padding: 6rem;
            text-align: center;

            }
            @media(max-width: 767px){
                max-width: 100%;
                
            }
      
    }

    &__text {
        padding: 2rem 0;
        text-align: left;
    }

    &__image {
        position: absolute;
        right: 0;
        padding: 0 2rem;
        top: -3rem;
        z-index: 10;
        img{
            max-width: 50rem;
            @media(max-width: 767px){
                max-width: 100%;
                
            }
        }
        @media(max-width: 767px){
            position: relative;
            padding: 0;
            
        }
        @media(max-width: 991px){
            top: 0;

            
        }
    }



 
    .left-bottom-image, .right-top-image{
        img{
            max-width: 15rem;
            position: absolute;
        }
    }

    .right-top-image{
        img{
            right: 0;
            top: 0;
            max-width: 35rem;
            z-index: 999;
            @media(max-width: 767px){
                max-width: 15rem;
                right: 0;
                bottom: -20rem;
                top: auto;
            }
        }
    }

    .left-bottom-image{
        @media(max-width: 767px){
          img{
            bottom: 21rem;
          }
        }
    }
}