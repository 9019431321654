.banner{
    width: 100%;
    margin-bottom: 10rem;
    // padding-top: 5rem;
    // padding-left: 25rem;
    // background-color: red;
    // box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.2);
    box-shadow: -2px 1px 12px -7px rgba(0,0,0,0.29);
    -webkit-box-shadow: -2px 1px 12px -7px rgba(0,0,0,0.29);
    -moz-box-shadow: -2px 1px 12px -7px rgba(0,0,0,0.29);

}
  
  
  /*Implement*/
  .sponsor-feature {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    //   margin: 5px 0;

    img{
margin: auto auto;    }
  }

  
  
 
