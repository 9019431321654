@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700&display=swap");

.name,
h1 {
  font-family: "Playfair Display", serif;
}

svg {
  fill: #ea5858;
}
