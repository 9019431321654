header{
    width: 100%;
    background-color: #5E043D;
    // box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.2);
    position: fixed;
    top: 0;
    z-index: 9999;

    .navbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        position: relative;
        z-index: 999;
        width: 100%;
        img{
            max-width: 15rem;

        }
        .nav-items{
            display: flex;
            width: 50%;
            max-width: 40rem;
            justify-content: space-between;
            align-items: center;
            color: var(--white);
           
            li{
                font-size: 2rem;
            }  
            @media(max-width: 767px) {
                display: none;

                &.active{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 100%;
                    max-width: 100%;
                    z-index: 999;
                    background-color: #5E043D;
                    height: 30vh;
                    margin: auto;
                    left: 0;
                    padding: 2rem 0;
                    top: 10rem;
                }
            } 
        }

        .hamburger{
            display: none;
            @media(max-width: 767px){
                display: block;
            }
        }
    }
}