.we-are-different {
  width: 100%;
  margin-bottom: 10rem;
  margin-top: 10rem;
  img {
    max-width: 7.5rem;
  }
  .container {
    text-align: center;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding-top: 10rem;

    h3 {
      padding: 1.5rem 0;
      font-weight: 700;
    }
    p {
      padding: 0;
    }
    @media (max-width: 967px) {
      text-align: center;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      text-align: center;
      grid-template-columns: repeat(1, 1fr);
      padding: 30px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
    .title1 {
      text-align: center;
    }
  }
}
