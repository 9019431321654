

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./reset";
@import "./variables";
@import "./base";
@import "./typography";
