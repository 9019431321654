@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700&display=swap");




h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  line-height: 1;
  font-weight: var(--fw-400);
}

h1,
.title1 {
  font-size: 6rem;
  position: relative;
  font-family: "Playfair Display", serif;

  @media (max-width: 767px) {
    font-size: 4rem;
  }
  &::before {
    content: "";
    position: absolute;
    width: 10rem;
    height: 2px;
    top: -5rem;
    background-color: var(--white);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
.title1--mod {
  &::before {
    content: "";
    background-color: var(--dark-violet);
    opacity: 0.6;
  }
}

h3,
.title3 {
  font-size: 2.2rem;
}

p {
  padding: 1rem 0;
  line-height: 1.5;
}
