// Variables
$white: #fff;

// Custom CSS
.portfolio-img {
  border-radius: 1rem;

  img {
    transform: scale(1.05);
    opacity: 1;
    filter: grayscale(70%);
    transition: transform 0.3s, opacity 0.3s, filter 1s;
  }

  &:hover {
    img {
      filter: grayscale(0%);
      transform: scale(1);
      opacity: 1;
    }

    .portfolio-hover {
      &::before {
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, 100%, 0);
      }
    }
  }
}

.portfolio-hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    background-color: rgba($white, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
      translate3d(0, -100%, 0);
    transition: transform 0.6s;
  }
}
