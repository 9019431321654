* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Playfair Display", serif;

  font-size: 1.6rem;
}
